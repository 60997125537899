import * as React from 'react'

import { Link } from '../Link/Link'

export type Props = {
  children: React.ReactNode
  href?: string
  onClick?: () => void
  secondary?: boolean
  disabled?: boolean
  className?: string
}

export const Button = ({
  children,
  href = '',
  onClick = () => {},
  secondary = false,
  disabled = false,
  className = '',
}: Props) => {
  if (href && !disabled) {
    return (
      <Link
        href={href}
        className={`animate-button relative inline-block w-full rounded-sm border py-4 px-10 text-center uppercase sm:w-auto  ${
          secondary
            ? `border-red bg-white text-red
        active:border-red-dark active:text-red-light active:shadow-xl
        disabled:cursor-default disabled:border-red disabled:bg-white disabled:text-red disabled:opacity-50`
            : `border-red bg-red text-white
            active:border-red-dark active:bg-red-dark active:text-white active:shadow-xl
            disabled:cursor-default disabled:border-red disabled:bg-red disabled:text-white disabled:opacity-50`
        } ${className}`}
      >
        {children}
        <span
          className={`target absolute inset-0 inline-grid w-full place-items-center leading-slack ${
            secondary ? `bg-red-light text-black` : `bg-white text-black`
          }`}
        >
          {children}
        </span>
      </Link>
    )
  }
  return (
    <button
      className={`animate-button relative inline-block w-full rounded-sm border py-4 px-10 text-center uppercase sm:w-auto  ${
        secondary
          ? `border-red bg-white text-red
    active:border-red-dark active:text-red-light active:shadow-xl
    disabled:cursor-default disabled:border-red disabled:bg-white disabled:text-red disabled:opacity-50`
          : `border-red bg-red text-white
        active:border-red-dark active:bg-red-dark active:text-white active:shadow-xl
        disabled:cursor-default disabled:border-red disabled:bg-red disabled:text-white disabled:opacity-50`
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      <span
        className={`target absolute inset-0 inline-grid w-full place-items-center leading-slack ${
          secondary ? `bg-red text-black` : `bg-white text-black`
        }`}
      >
        {children}
      </span>
    </button>
  )
}
