import * as React from 'react'
import { Link } from 'elements/Link/Link'

export type Props = {
  alignment: string
  name: string
  href: string
  xCoordinate: number
  yCoordinate: number
  lineLength: number
}

export const MapItem = ({ alignment, name, href, xCoordinate, yCoordinate, lineLength }: Props) => {
  return (
    <Link href={href}>
      <span
        style={{
          top: `${xCoordinate}%`,
          left: `${yCoordinate}%`,
          minHeight:
            alignment === 'top' || alignment === 'bottom' ? `${lineLength + 24 + 36}px` : 'auto',
        }}
        className={`
              sally-link group
              absolute
              inline-flex        
              sally-align-${alignment} 
          `}
      >
        <div
          className={`
              flex 
              h-6
              w-6 
              items-center
              justify-center
              rounded-full border  
              border-white 
              opacity-60 
              duration-150 
              group-hover:border-red-active
              group-hover:opacity-100
              group-hover:shadow-sally-dot
              `}
        >
          <div
            className={`
                h-4
                w-4
                rounded-full bg-white
                group-hover:bg-red-active
          `}
          ></div>
        </div>
        <div
          style={{ width: `${lineLength}px` }}
          className={`
          sally-arm
          relative
        h-px 
          bg-white 
          opacity-60 
          group-hover:opacity-100
          `}
        ></div>

        <p
          className={`
                mb-0 
              inline-block 
                rounded-md bg-white 
              bg-opacity-60 
                px-4 
                py-1 
                text-lg 
                font-bold
                text-black
            `}
        >
          {name}
        </p>
      </span>
    </Link>
  )
}
