import Image from '@next/image'
import clsx from 'clsx'
import { useMedia } from 'hooks/useMedia'
import { useMarginMaker } from 'hooks/useMarginMaker'
import { ImageType } from 'typings/image'
import { ButtonSelectSwitch, LinkType } from '../../elements/ButtonSelectSwitch/ButtonSelectSwitch'

export type FullWidthTeaserProps = {
  image: ImageType
  mobileImage?: ImageType
  headline: string
  text: string
  linkList: LinkType[]
  darkTheme?: boolean
  spacing?: string
}

export const FullWidthTeaser = ({
  image,
  mobileImage = image,
  headline,
  text,
  linkList,
  darkTheme = false,
  spacing,
}: FullWidthTeaserProps) => {
  const isDesktop = useMedia('lg')
  const margin = useMarginMaker(spacing, 'lg')

  return (
    <section className="relative h-100vh w-full lg:h-hd-vw xl:h-33vw" style={margin}>
      {isDesktop && image?.url ? (
        <Image src={image.url} fill style={{ objectFit: 'cover' }} alt={image.alt} />
      ) : (
        mobileImage?.url && (
          <Image src={mobileImage.url} fill style={{ objectFit: 'cover' }} alt={mobileImage.alt} />
        )
      )}

      <div
        className={clsx(
          'absolute top-0 left-4 flex h-full flex-col justify-end pr-4 pb-10 lg:left-16 lg:max-w-43vw lg:justify-center lg:p-0 lg:pb-0 xl:left-24',
          darkTheme ? 'text-black-light' : 'text-white',
        )}
      >
        <h3 className="text-h2-mobile lg:text-h2-desk 2xl:text-h2-large">{headline}</h3>
        <div
          className="text-h3-mobile lg:text-h3-desk 2xl:text-h3-large"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        <ButtonSelectSwitch darkTheme={darkTheme} linkList={linkList} transparent />
      </div>
    </section>
  )
}
