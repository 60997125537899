import { useRouter } from 'next/router'
import { LinkType } from './ButtonSelectSwitch'
import { Icon } from '../Icon/Icon'

export type Props = {
  linkList: LinkType[]
  fullWidth: boolean
  className?: string
}

export const LinkSelect = ({ linkList, fullWidth, className = '' }: Props) => {
  const router = useRouter()
  const renderOptions = () =>
    linkList.map((link, index) => (
      <option value={link.href} key={index}>
        {link.text}
      </option>
    ))
  const onChange = (data) => {
    router.push(data.target.value)
  }
  return (
    <div className={className}>
      <div className={`relative pr-2 ${fullWidth ? '' : 'sm:inline-block sm:pr-0'}`}>
        <select
          defaultValue="0"
          onChange={onChange}
          className={`box-content h-9 w-full cursor-pointer appearance-none border border-gray bg-white pl-2 leading-9 outline-none ${
            fullWidth ? '' : 'sm:w-72'
          }`}
        >
          <option value="" disabled key={-1}>
            Bitte wählen
          </option>
          {renderOptions()}
        </select>
        <div className="pointer-events-none absolute top-px right-0 my-1 inline-flex h-7 w-9 flex-col items-center justify-center border-l border-gray">
          <Icon size={12} name="chevron-up" className="mb-0.5" />
          <Icon size={12} name="chevron-down" />
        </div>
      </div>
    </div>
  )
}
