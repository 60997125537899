import { useMarginMaker } from 'hooks/useMarginMaker'

export type TextModuleProps = {
  text: string
  spacing?: string
}

export const TextModule = ({ text, spacing }: TextModuleProps) => {
  const margin = useMarginMaker(spacing)
  return (
    <article
      style={margin}
      className="richText xl:text-container mx-auto max-w-[60rem] px-4 sm:px-0 xl:max-w-text"
      dangerouslySetInnerHTML={{ __html: text }}
    ></article>
  )
}
