import * as React from 'react'
import { SallyLink } from '../Sally'
import Image from '@next/image'
import { MapItem } from './MapItem'
import { Icon } from 'elements/Icon/Icon'
import { useMedia } from 'hooks/useMedia'
import { useRef, useState } from 'react'
import { ImageType } from 'typings/image'
import { animated, useSpring } from '@react-spring/web'
import { useInView } from 'react-intersection-observer'
import clsx from 'clsx'

export type Props = {
  image: ImageType
  hasSwipe: boolean
  teaserList: SallyLink[]
}

export const Map = ({
  image,
  teaserList,
}: // hasSwipe
Props) => {
  const isDesktop = useMedia('md')
  const imageRef = useRef(null)
  const [reachedBeginning, setReachedBeginning] = useState(true)
  const [reachedEnd, setReachedEnd] = useState(false)
  const hasSwipe = true

  const onScroll = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const currentScrollX = e.currentTarget.scrollLeft

    setReachedBeginning(currentScrollX === 0)
    setReachedEnd(currentScrollX + 375 >= imageRef?.current?.firstChild.clientWidth)
  }

  const [iconRef, inView] = useInView({
    triggerOnce: true,
  })

  const wobble = useSpring({
    transform: inView ? 'scale(1)' : 'scale(0.6)',
    config: { mass: 1.5, tension: 500, friction: 18 },
    delay: 250,
  })

  return (
    <div onScroll={hasSwipe ? onScroll : undefined} className={clsx(hasSwipe && 'overflow-scroll')}>
      <div
        style={{
          width: `${hasSwipe && !isDesktop && imageRef?.current?.firstChild.clientWidth}px`,
          height: `${hasSwipe && !isDesktop && imageRef?.current?.firstChild.clientHeight}px`,
        }}
        ref={imageRef}
        className={`relative  h-screen w-auto md:h-auto `}
      >
        <Image
          src={image.url}
          width={923}
          height={800}
          layout={!isDesktop ? 'fixed' : 'responsive'}
          objectFit="cover"
          alt={image.alt ? image.alt : ''}
          className="object-left-top"
        />
        {teaserList?.map((item, i) => (
          <MapItem
            key={i}
            name={item.name}
            alignment={item.alignment}
            href={item.href}
            xCoordinate={item.xCoordinate}
            yCoordinate={item.yCoordinate}
            lineLength={item.lineLength}
          />
        ))}
        {!isDesktop && hasSwipe && (
          <div
            className={clsx(
              'absolute left-0 bottom-0 flex w-screen items-center justify-center bg-gradient py-4 duration-300',
              reachedBeginning || reachedEnd ? 'opacity-100' : 'opacity-0',
              reachedBeginning && 'left-0 right-auto',
              reachedEnd && 'right-0 left-auto',
            )}
          >
            <animated.div style={wobble} ref={iconRef} className={`flex items-center py-2`}>
              <Icon
                name={'swipe-right'}
                className={`mr-2 w-4 text-white ${!reachedBeginning && 'scale-x-[-1]'}`}
              />
              <p className="mb-0 uppercase text-white">Mehr anzeigen</p>
            </animated.div>
          </div>
        )}
      </div>
    </div>
  )
}
