import { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick'
import { LargeStageSlide, SlideData } from './LargeStageSlide'
import { Icon } from 'elements/Icon/Icon'
import { useMedia } from 'hooks/useMedia'
import { useMarginMaker } from 'hooks/useMarginMaker'
import { Cursor } from './components/Cursor'
import { useQueryClient } from 'react-query'
import { STAGE_LARGE_PRESENT } from 'hooks/useToggleNavBackground'
import clsx from 'clsx'

export type LargeStageProps = {
  teaserList: SlideData[]
  slideMilSeconds?: number
  spacing?: string
}

export const LargeStage = ({ teaserList, slideMilSeconds = 15000, spacing }: LargeStageProps) => {
  const queryClient = useQueryClient()
  const slider = useRef(null)
  const [slideIndex, setSlideIndex] = useState(0)
  const ref = useRef(null)
  const [width, setWidth] = useState(null)
  const [cursor, setCursor] = useState(false)
  const [isEntered, setisEntered] = useState(false)
  const [isCurrentSlide, setIsCurrentSlide] = useState(0)
  const [isVideo, setIsVideo] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)
  const isDesktop = useMedia('md')
  const margin = useMarginMaker(spacing)

  useEffect(() => {
    setWidth(ref?.current?.clientWidth)
  }, [slider])

  useEffect(() => {
    queryClient.setQueryData<boolean>(STAGE_LARGE_PRESENT, true)

    return () => {
      queryClient.setQueryData<boolean>(STAGE_LARGE_PRESENT, false)
    }
  }, [queryClient])

  const sliderSettings = {
    autoplay: true,
    autoplaySpeed: slideMilSeconds,
    pauseOnHover: false,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_currentSlide: number, nextSlide: number) => {
      setSlideIndex(nextSlide)
      // setIsCurrentSlide(Math.ceil(_currentSlide))
    },
    afterChange: (current: number) => setIsCurrentSlide(Math.ceil(current)),
  }

  const Dots = () => (
    <ul className="ml-2 flex flex-row justify-between last:mr-0">
      {teaserList?.map((_slide, index) => (
        <li className="mr-2" key={_slide.headline + index}>
          <a className="block cursor-pointer p-2" onClick={() => slider.current.slickGoTo(index)}>
            <div className="relative block h-0 w-12 border-b-2 border-white-transparent">
              <span
                style={{ animationDuration: `${slideMilSeconds}ms` }}
                className={clsx(
                  'block h-0 w-0 border-b-2 border-white text-hidden',
                  index === slideIndex && 'loader-animation absolute top-0 left-0 w-12',
                )}
              >
                {index}
              </span>
            </div>
          </a>
        </li>
      ))}
    </ul>
  )

  const playing = () => {
    if (!setCursor || isEntered || !isVideo) return
    setIsPlaying((prev) => !prev)
  }

  useEffect(() => {
    const element = ref.current
    function activateCursor() {
      if (!setCursor && !isVideo) return
      document.querySelector('body').classList.add('cursor-none')
      setCursor(true)
    }

    function disableCursor() {
      if (!setCursor && !isVideo) return
      document.querySelector('body').classList.remove('cursor-none')
      setCursor(false)
    }

    if (!setCursor || !element) return
    window.addEventListener('scroll', (ev) => disableCursor())

    element.addEventListener('mousemove', (ev) => {
      if (ev.currentTarget === element) {
        return activateCursor()
      }
    })
    element.addEventListener('mouseenter', activateCursor)
    element.addEventListener('mouseleave', disableCursor)

    return () => {
      if (!setCursor || !element) return
      element.removeEventListener('mouseenter', activateCursor)
      element.removeEventListener('mouseleave', disableCursor)
      window.removeEventListener('scroll', (ev) => disableCursor())
      element.removeEventListener('mousemove', (ev) => {
        if (ev.currentTarget === element) {
          return activateCursor()
        }
      })
    }
  }, [isVideo])

  return (
    <section style={margin} className={clsx('relative -mt-14 md:-mt-20', !isVideo && 'cursor-auto')} ref={ref}>
      {isDesktop && cursor && !isEntered && isVideo ? <Cursor isPlaying={isPlaying} /> : null}
      <Slider {...sliderSettings} ref={slider} className="w-screen">
        {teaserList?.map((data, index) => {
          if (
            data.image &&
            isCurrentSlide === index &&
            data.image.filename.includes('mp4') &&
            !isVideo
          ) {
            setIsVideo(true)
          }
          if (
            data.image &&
            isCurrentSlide === index &&
            !data.image.filename.includes('mp4') &&
            isVideo
          ) {
            setIsVideo(false)
          }

          return (
            <LargeStageSlide
              key={data.headline}
              data={data}
              width={width}
              onEnter={() => isVideo && setisEntered(true)}
              onLeave={() => isVideo && setisEntered(false)}
              playing={playing}
            />
          )
        })}
      </Slider>

      {teaserList?.length > 1 && (
        <div
          className="absolute bottom-0 left-0 flex w-full items-center justify-center"
          onMouseEnter={() => isVideo && setisEntered(true)}
          onMouseLeave={() => isVideo && setisEntered(false)}
        >
          <button
            onClick={() => slider.current.slickPrev()}
            className="inline-flex cursor-pointer items-center justify-center py-2 px-3 text-white text-opacity-50 hover:text-opacity-100 focus:outline-none"
          >
            <Icon name="filled-chevron-left" size={15} />
          </button>
          <Dots />
          <button
            onClick={() => slider.current.slickNext()}
            className="inline-flex cursor-pointer items-center justify-center py-2 px-3 text-white text-opacity-50 hover:text-opacity-100 focus:outline-none"
          >
            <Icon name="filled-chevron-right" size={15} />
          </button>
        </div>
      )}
    </section>
  )
}
