import { ReactNode, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import tailwindConfig from '../../tailwind.config'

type WobbleProps = {
  changeColor: boolean
  children: ReactNode
}
export const Wobble = ({ changeColor, children }: WobbleProps) => {
  const [isTouched, setIsTouched] = useState(false)
  const wobble = useSpring({
    transform: isTouched ? 'scale(1.1)' : 'scale(1)',
    color:
      changeColor && isTouched
        ? tailwindConfig.theme.colors.red.DEFAULT
        : tailwindConfig.theme.colors.gray.dark,
    config: { mass: 1.5, tension: 500, friction: 18 },
  })

  return (
    <animated.div
      style={wobble}
      onMouseEnter={() => setIsTouched(true)}
      onMouseLeave={() => setIsTouched(false)}
      className={`inline-block`}
    >
      {children}
    </animated.div>
  )
}
