import { Button } from '../Button/Button'
import { LinkSelect } from './LinkSelect'
import { TextLink, TextLinkTheme } from '../TextLink/TextLink'
import clsx from 'clsx'

export type LinkType = {
  text: string
  href: string
}

export type Props = {
  linkList: LinkType[]
  className?: string
  fullWidth?: boolean
  transparent?: boolean
  darkTheme?: boolean
}

export const ButtonSelectSwitch = ({
  linkList,
  className = '',
  fullWidth,
  transparent,
  darkTheme,
}: Props) => {
  const showSelect = !!linkList && linkList.length > 2

  const renderButtons = () => {
    const primary = !!linkList[0] && linkList[0]
    const secondary = !!linkList[1] && linkList[1]

    return (
      <div
        className={clsx(
          'flex-col text-center sm:text-left',
          !fullWidth && 'sm:flex-row',
          className && className,
        )}
      >
        {primary && (
          <Button href={primary.href} className={clsx(fullWidth && 'sm:w-full')}>
            {primary.text}
          </Button>
        )}
        {secondary && !transparent && (
          <Button
            href={secondary.href}
            className={clsx('mt-4', fullWidth ? 'sm:w-full' : 'sm:mt-0 sm:ml-10')}
            secondary
          >
            {secondary.text}
          </Button>
        )}
        {secondary && transparent && (
          <TextLink
            theme={darkTheme ? TextLinkTheme.DARK : TextLinkTheme.LIGHT}
            href={secondary.href}
            className={clsx(
              'mt-2 py-2',
              fullWidth ? 'justify-center sm:mx-0 sm:w-full' : 'sm:mt-0 sm:ml-10',
            )}
          >
            {secondary.text}
          </TextLink>
        )}
      </div>
    )
  }

  return showSelect ? (
    <LinkSelect linkList={linkList} className={className} fullWidth={fullWidth} />
  ) : (
    renderButtons()
  )
}
