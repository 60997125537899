import Image from '@next/image'
import clsx from 'clsx'

import { TextLink, TextLinkTheme } from '../../elements/TextLink/TextLink'
import { useMedia } from '../../hooks/useMedia'
import { ImageType } from 'typings/image'
import { Link } from 'elements/Link/Link'

export type Props = {
  image: ImageType
  mobileImage: ImageType
  rich_text: string
  headline: string
  path: string
  linkText: string
  darkTheme?: boolean
  className?: string
  isCurrentSlide?: boolean
}

export const TeaserSlide = ({
  image,
  mobileImage,
  rich_text,
  headline,
  linkText,
  darkTheme,
  path = '/',
  className = '',
  isCurrentSlide,
}: Props) => {
  const isDesktop = useMedia('md')
  return (
    <Link
      href={path}
      className={clsx(isCurrentSlide ? 'pointer-events-auto' : 'pointer-events-none')}
    >
      <div
        className={clsx(
          'teaser-slide animate-arrow xl:animate-arrow-large relative block h-full',
          className && className,
        )}
      >
        {isDesktop
          ? image && (
              <div className="relative aspect-[853/480]">
                <Image
                  src={image.url}
                  fill
                  sizes="(max-width: 768px) 100vw; 50vw"
                  style={{ objectFit: 'cover' }}
                  alt={image.alt}
                />
              </div>
            )
          : mobileImage && (
              <div className={`absolute inset-0 h-full w-full`}>
                <Image src={mobileImage.url} fill style={{ objectFit: 'cover' }} alt={image.alt} />
              </div>
            )}
        <div
          className={clsx(
            'relative flex h-slide-mobile max-w-[90%] flex-col justify-center px-8 py-6 sm:px-12 sm:py-10 md:h-slide-ipad lg:absolute lg:top-0 lg:left-8 lg:h-full lg:w-full lg:max-w-[28.75rem] lg:px-0 lg:py-0 xl:left-24 xl:w-27vw',
            darkTheme ? 'text-black-light' : 'text-white',
          )}
        >
          <strong className="mb-4 inline-block text-lg font-bold uppercase tracking-[0.125rem] lg:mb-6 lg:text-xl">
            {headline}
          </strong>
          <div
            dangerouslySetInnerHTML={{ __html: rich_text }}
            className="richText max-h-75% overflow-hidden overflow-ellipsis text-base line-clamp-10 xl:max-h-full xl:text-2xl"
          ></div>
          {linkText && (
            <TextLink
              iconLeft
              iconSize={10}
              theme={darkTheme ? TextLinkTheme.DARK : TextLinkTheme.LIGHT}
            >
              {linkText}
            </TextLink>
          )}
        </div>
      </div>
    </Link>
  )
}
