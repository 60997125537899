import { Headline, HeadlineType } from '../../elements/Headline/Headline'
import { Map } from './components/Map'
import { ImageType } from 'typings/image'
import { useMarginMaker } from 'hooks/useMarginMaker'

export type SallyLink = {
  name: string
  href: string
  alignment: 'left' | 'right' | 'top' | 'bottom'
  lineLength: number
  xCoordinate: number
  yCoordinate: number
}

export type SallyProps = {
  headline: string
  richText: string
  image: ImageType
  // hasSwipe: boolean
  teaserList: SallyLink[]
  spacing?: string
}

export const Sally = ({ headline, richText, image, teaserList, spacing }: SallyProps) => {
  const margin = useMarginMaker(spacing)
  const hasSwipe = true
  return (
    <section style={margin} className="container flex w-screen flex-col px-0 lg:flex-row">
      <div className="px-4 pt-10 lg:w-1/3 lg:pt-12 lg:pr-8 lg:pl-20">
        <Headline type={HeadlineType.h2} className={`text-red`}>
          {headline}
        </Headline>
        <div dangerouslySetInnerHTML={{ __html: richText }}></div>
      </div>
      <div className={`overflow-hidden lg:w-2/3`}>
        <Map hasSwipe={hasSwipe} image={image} teaserList={teaserList} />
      </div>
    </section>
  )
}
