import NextImage from '@next/image'
import ReactPlayer from 'react-player'
import { Headline, HeadlineType } from '../../elements/Headline/Headline'
import { useMedia } from 'hooks/useMedia'
import { ButtonSelectSwitch, LinkType } from '../../elements/ButtonSelectSwitch/ButtonSelectSwitch'
import { useState } from 'react'
import { ImageType } from 'typings/image'
import { useInView } from 'react-intersection-observer'

export type SlideData = {
  headline: string
  linkList: LinkType[]
  image?: ImageType
  mobileImage?: ImageType
  navContrastMode: boolean
}

export type Props = {
  width: number
  data: SlideData
  className?: string
  onEnter?: () => void
  onLeave?: () => void
  playing?: () => void
}

export const LargeStageSlide = ({ data, className = '', onEnter, onLeave, playing }: Props) => {
  const {
    headline,
    linkList,
    image,
    mobileImage = image,
    // navContrastMode,
  } = data

  const [isPlaying, setIsPlaying] = useState(true)
  const isDesktop = useMedia('md')
  const videoURL = image && image.filename && image.filename.includes('mp4') ? image.url : null
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <div
      className={`relative h-stage-mobile sm:h-stage-desktop ${className}`}
      onClick={videoURL && playing}
    >
      {videoURL ? (
        <div className="relative h-full w-full" ref={ref}>
          {inView ? (
            <ReactPlayer
              className="react-player"
              width="100%"
              height="100"
              playsinline
              loop
              playing={isPlaying}
              volume={0}
              muted
              url={image.url}
              onClick={() => setIsPlaying((prev) => !prev)}
            />
          ) : null}
        </div>
      ) : image?.url && isDesktop ? (
        <NextImage src={image.url} alt="Stage Image" fill style={{ objectFit: 'cover' }} />
      ) : (
        mobileImage?.url && (
          <NextImage src={mobileImage.url} alt="Stage Image" fill style={{ objectFit: 'cover' }} />
        )
      )}
      <div
        className={`absolute bottom-6 left-0 cursor-default p-4 sm:left-16 md:bottom-10 lg:bottom-28`}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
      >
        <Headline
          type={HeadlineType.h1}
          className={`mb-6 text-left text-white sm:mb-8 sm:max-w-lg`}
        >
          {headline}
        </Headline>
        <div>
          <ButtonSelectSwitch linkList={linkList} />
        </div>
      </div>
    </div>
  )
}
