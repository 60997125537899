import { Children, useState, useRef } from 'react'
import Slider, { Settings } from 'react-slick'
import { useTrail, a } from '@react-spring/web'
import useWindowSize from '@charlietango/use-window-size'

import { SmallTeaser, Props as SmallTeaserProps } from './SmallTeaser'
import { Headline, HeadlineType } from '../../elements/Headline/Headline'
import { useMedia } from 'hooks/useMedia'
import { useMarginMaker } from 'hooks/useMarginMaker'
import { SlideNav } from '../../elements/SlideNav/SlideNav'
import { useTranslation } from 'react-i18next'

export type SmallTeaserSliderProps = {
  teaserList: SmallTeaserProps[]
  headline: string
  spacing?: string
}

const TEASER_COUNT = 3

const Trail = ({ children }) => {
  const items = Children.toArray(children)
  const trail = useTrail(items.length, {
    config: { mass: 4, tension: 1000, friction: 200 },
    opacity: 1,
    from: { opacity: 0, height: 0 },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className="mb-6 overflow-y-hidden " style={style}>
          <a.div>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  )
}

export const SmallTeaserSlider = ({ teaserList, headline, spacing }: SmallTeaserSliderProps) => {
  const isDesktop = useMedia('md')
  const margin = useMarginMaker(spacing)
  let slider = useRef(null)
  const [index, setIndex] = useState(0)
  const [itemsToShow, setItemsToShow] = useState(TEASER_COUNT)
  const { t } = useTranslation()

  const settings: Settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 300,
    draggable: false,
    slidesToShow: teaserList.length < TEASER_COUNT ? teaserList.length : TEASER_COUNT,
    slidesToScroll: TEASER_COUNT,
    beforeChange: (_currentSlide: number, nextSlide: number) => {
      setIndex(nextSlide)
    },
  }
  const disablePrev = index === 0
  const disableNext = index === teaserList.length - TEASER_COUNT

  const renderItems = (count?: number) => {
    const items = count ? teaserList.slice(0, count) : teaserList
    return items.map(({ image, headline, rich_text, href, path, linkText }, index) => {
      return (
        <SmallTeaser
          key={index}
          image={image}
          headline={headline}
          rich_text={rich_text}
          href={href}
          path={path}
          linkText={linkText}
        />
      )
    })
  }

  const showMore = () => {
    setItemsToShow(itemsToShow + 3)
  }

  return (
    <section className="px-auto container mt-4 mb-6 max-w-lg md:my-14 md:w-screen md:px-16 small-teaser-slider" style={margin}>
      <div
        className={`flex items-center justify-between px-2 pt-4 md:mx-4 md:mb-10 md:px-0 md:pb-2 ${
          headline && 'md:border-b md:border-gray'
        }`}
      >
        <Headline type={HeadlineType.h2} className="mb-6 inline-block pl-2 text-red md:mb-1">
          {headline}
        </Headline>
        <SlideNav
          clickPrev={() => slider.current.slickPrev()}
          clickNext={() => slider.current.slickNext()}
          disablePrev={disablePrev}
          disableNext={disableNext}
          className={`hidden items-center ${teaserList.length <= 3 ? 'hidden' : 'md:inline-flex'}`}
        />
      </div>
      {isDesktop ? (
        <Slider {...settings} ref={(sliderRef) => (slider.current = sliderRef)}>
          {renderItems()}
        </Slider>
      ) : (
        <>
          <Trail>{renderItems(itemsToShow)}</Trail>
          {itemsToShow < teaserList.length && (
            <button
              className="mx-auto block cursor-pointer py-2 text-center text-sm underline"
              onClick={() => showMore()}
            >
              {t('Show More')}
            </button>
          )}
        </>
      )}
    </section>
  )
}
