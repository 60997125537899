import useMousePosition from '../helpers/useMousePosition'
import { useState, useEffect } from 'react'

export type Props = {
  isPlaying: boolean
}

export const Cursor = ({ isPlaying }: Props) => {
  const { clientX, clientY } = useMousePosition()
  const [Playing, setIsPlaying] = useState(false)

  useEffect(() => {
    setIsPlaying((prev) => !prev)
  }, [isPlaying])

  return (
    <div className={`cursor pointer-events-none fixed inset-0 z-50 rounded-full`}>
      <svg
        viewBox="0 0 56 56"
        width={56}
        height={56}
        className={`-translate-x-50% -translate-y-50% absolute transform text-white text-opacity-50`}
        style={{ left: clientX, top: clientY }}
      >
        <circle cx="28" cy="28" r="26" fill="currentColor" stroke="#C01129" />
        {!Playing ? (
          <path d="M23.3335 20V36L36.6668 28L23.3335 20Z" fill="white" stroke="#C01129" />
        ) : (
          <>
            <rect width="4" height="16" x="22" y="20" rx="1" ry="1" fill="white" stroke="#C01129" />
            <rect width="4" height="16" x="30" y="20" rx="1" ry="1" fill="white" stroke="#C01129" />
          </>
        )}
      </svg>
    </div>
  )
}
