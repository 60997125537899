import Image from '@next/image'
import { useMedia } from 'hooks/useMedia'
import { Link } from 'elements/Link/Link'
import { TextLink, TextLinkTheme } from 'elements/TextLink/TextLink'
import { ImageType } from 'typings/image'
import { useMarginMaker } from 'hooks/useMarginMaker'

export type LargeTeaserProps = {
  image: ImageType
  text_one: string
  headline: string
  mobileImage?: ImageType
  linkText?: string
  href?: string
  darkTheme?: boolean
  spacing?: string
}

export const LargeTeaser = ({
  image,
  text_one,
  headline,
  mobileImage = image,
  linkText,
  href,
  darkTheme = false,
  spacing,
}: LargeTeaserProps) => {
  const isDesktop = useMedia('lg')
  const margin = useMarginMaker(spacing, 'lg')

  return (
    <section className={`container`} style={margin}>
      <div className={`relative bg-red-light`}>
        {isDesktop ? (
          image?.url &&
          (href ? (
            <Link href={href} className="relative block aspect-[1280/480]">
              <Image src={image.url} fill style={{ objectFit: 'cover' }} alt="" />
            </Link>
          ) : (
            <div className="relative aspect-[1280/480]">
              <Image src={image.url} fill style={{ objectFit: 'cover' }} alt="" />
            </div>
          ))
        ) : (
          <div className={`absolute inset-0 h-full w-full`}>
            {href ? (
              <Link href={href}>
                <Image src={mobileImage?.url} fill style={{ objectFit: 'cover' }} alt="" />
              </Link>
            ) : (
              <Image src={mobileImage?.url} fill style={{ objectFit: 'cover' }} alt="" />
            )}
          </div>
        )}
        <div
          className={`relative flex flex-col justify-center px-4 py-8 sm:max-w-lg sm:px-6 sm:py-10 lg:absolute lg:top-0 lg:left-16 lg:h-full lg:px-0 lg:py-0 ${
            darkTheme ? 'text-black-light' : 'text-white'
          }`}
        >
          <strong className="mb-2 inline-block text-p-small uppercase lg:mb-4">{headline}</strong>
          <div
            dangerouslySetInnerHTML={{ __html: text_one }}
            className="richText text-h3-mobile lg:text-h3-desk 2xl:text-h3-large "
          ></div>
          {href && linkText && (
            <TextLink
              href={href}
              theme={darkTheme ? TextLinkTheme.DARK : TextLinkTheme.LIGHT}
              iconSize={10}
            >
              {linkText}
            </TextLink>
          )}
        </div>
      </div>
    </section>
  )
}
