import { useInView } from 'react-intersection-observer'
import { Trail } from '../../animations/Trail'
import { Headline, HeadlineType } from '../../elements/Headline/Headline'
import { ButtonSelectSwitch, LinkType } from '../../elements/ButtonSelectSwitch/ButtonSelectSwitch'
import { useMarginMaker } from 'hooks/useMarginMaker'

export type TextTeaserProps = {
  headline: string
  text: string
  linkList?: LinkType[]
  spacing?: string
}

export const TextTeaser = ({ headline, text, linkList, spacing }: TextTeaserProps) => {
  const [ref, inView] = useInView({
    rootMargin: '-150px 0px',
    triggerOnce: true,
  })
  const margin = useMarginMaker(spacing)

  return (
    <section style={margin} ref={ref} className="mx-auto max-w-[60rem] px-4 sm:px-0 xl:max-w-text">
      <Trail open={inView}>
        <Headline type={HeadlineType.h2} className="text-center">
          {headline}
        </Headline>
        <div className="mx-auto mb-6 text-center" dangerouslySetInnerHTML={{ __html: text }} />
        <ButtonSelectSwitch linkList={linkList} className="justify-center sm:flex" />
      </Trail>
    </section>
  )
}
