import { Icon } from 'elements/Icon/Icon'
import { useMarginMaker } from 'hooks/useMarginMaker'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player/lazy'
import { useInView } from 'react-intersection-observer'

export type VideoModuleProps = {
  /**
   * eine URL
   */
  href: string
  thumbnail?: {url?: string}
  title: string
  speaker?: string
  description?: string
  spacing?: string
}

export const VideoModule = ({
  href,
  thumbnail,
  title,
  speaker,
  description,
  spacing,
}: VideoModuleProps) => {
  const { t } = useTranslation()
  const [displayDuration, setDisplayDuration] = useState<string>('')
  const margin = useMarginMaker(spacing)
  const [ref, inView] = useInView({ triggerOnce: true })

  const generateDisplayDuration = (duration: number): string => {
    const converted = new Date(duration * 1000).toISOString().substr(11, 8)
    const pairs = converted.split(':')
    // if there's no hours, remove first pair of zeros
    // so we display "00:13" instead of "00:00:13"
    if (pairs[0] === '00') {
      pairs.shift()
    }

    return pairs.join(':')
  }

  return (
    <section className="px-4" style={margin} ref={ref}>
      <div className="md:mx-auto md:max-w-[840px]">
        <div className="relative pt-[56.25%]">
          {inView ? (
            <ReactPlayer
              controls
              url={href}
              light={thumbnail?.url}
              width="100%"
              height="100%"
              className="absolute top-0 left-0"
              onDuration={(duration) => {
                setDisplayDuration(generateDisplayDuration(duration))
              }}
            />
          ) : null}
        </div>
        <div className="mt-6 md:mx-auto md:mt-8 md:max-w-[624px]">
          <div className="flex flex-row ">
            <div className="mb-2 md:mx-auto md:flex-1 ">
              <h2 className="mb-2 text-xl font-medium md:max-w-[624px] md:text-3xl">{title}</h2>
              {speaker ? (
                <div className="text-base">
                  <span className="font-bold">{t('Speaker')}:</span> {speaker}
                </div>
              ) : null}
              {displayDuration ? (
                <div className="text-base">
                  <span className="font-bold">{t('Duration')}:</span> {displayDuration}
                </div>
              ) : null}
            </div>
            <div className="ml-6 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-red p-2 md:h-14 md:w-14 md:p-3">
              <Icon name="video" className="h-full w-full text-red" />
            </div>
          </div>
          <div>
            {description ? (
              <div className="richText" dangerouslySetInnerHTML={{ __html: description }}></div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}
