import { useState, useRef } from 'react'
import Slider from 'react-slick'
import { TeaserSlide, Props as TeaserSlideProps } from './TeaserSlide'
import { Headline, HeadlineType } from '../../elements/Headline/Headline'
import { SlideNav } from '../../elements/SlideNav/SlideNav'
import { useMedia } from '../../hooks/useMedia'
import { useMarginMaker } from 'hooks/useMarginMaker'
import clsx from 'clsx'

export type TeaserSliderProps = {
  teaserList: TeaserSlideProps[]
  headline: string
  darkTheme?: boolean
  spacing?: string
}

export const TeaserSlider = ({ teaserList, headline, darkTheme = false, spacing }: TeaserSliderProps) => {
  let slider = useRef(null)
  const [firstSlideActive, setFirstSlideActive] = useState(true)
  const [lastSlideActive, setLastSlideActive] = useState(false)
  const [isCurrentSlide, setIsCurrentSlide] = useState(0)
  const isDesktop = useMedia('md')
  const isMobile = !isDesktop
  const margin = useMarginMaker(spacing)

  const TEASER_COUNT = isDesktop ? 1.5 : 1.075
  const TEASER_OFFSET = isDesktop ? 0.5 : 0.075

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 800,
    draggable: false,
    slidesToShow: TEASER_COUNT,
    slidesToScroll: TEASER_COUNT - TEASER_OFFSET,
    beforeChange: (_currentSlide: number, nextSlide: number) => {
      setFirstSlideActive(nextSlide === 0)
      setLastSlideActive(nextSlide === teaserList.length - TEASER_COUNT)
    },
    afterChange: (current: number) => setIsCurrentSlide(Math.ceil(current)),
    useTransform: true,
    focusOnSelect: true,
  }

  const renderItems = () => {
    return teaserList.map(({ image, mobileImage, rich_text, linkText, path, headline }, index) => {
      return (
        <TeaserSlide
          key={index}
          image={image}
          mobileImage={mobileImage}
          rich_text={rich_text}
          headline={headline}
          linkText={linkText}
          path={path}
          darkTheme={darkTheme}
          className="mx-2 sm:mx-4"
          isCurrentSlide={index === isCurrentSlide}
        />
      )
    })
  }
  return (
    <section className="max-w-[100vw]" style={margin}>
      <div className="mx-4 flex items-center justify-between border-gray pt-4 sm:mx-14 sm:mb-10 sm:border-b sm:pb-2 lg:mx-20">
        <Headline type={HeadlineType.h2} className={`mb-6 inline-block text-red sm:mb-1`}>
          {headline}
        </Headline>
        {isDesktop && (
          <SlideNav
            clickPrev={() => slider.current.slickPrev()}
            clickNext={() =>
              lastSlideActive ? slider.current.slickGoTo(0) : slider.current.slickNext()
            }
            disablePrev={firstSlideActive}
          />
        )}
      </div>
      <Slider
        {...settings}
        className={clsx(
          'animated mb-4 transform duration-[800ms] sm:mb-0',
          lastSlideActive ? 'mr-3 sm:mr-8 lg:mr-16' : 'ml-3 sm:ml-8 lg:ml-16',
        )}
        ref={slider}
      >
        {renderItems()}
      </Slider>
      {isMobile && (
        <div className={`mx-4 flex flex-row-reverse border-t border-gray pt-3`}>
          <SlideNav
            clickPrev={() => slider.current.slickPrev()}
            clickNext={() =>
              lastSlideActive ? slider.current.slickGoTo(0) : slider.current.slickNext()
            }
            disablePrev={firstSlideActive}
          />
        </div>
      )}
    </section>
  )
}
