import { useMarginMaker } from 'hooks/useMarginMaker'
import { Headline, HeadlineType } from '../../elements/Headline/Headline'
import { Icon } from 'elements/Icon/Icon'
import Image from '@next/image'
import { Link } from 'elements/Link/Link'
import { Wobble } from '../../animations/Wobble'
import { Trail } from '../../animations/Trail'
import { useInView } from 'react-intersection-observer'
import { ImageType } from 'typings/image'
import { useTranslation } from 'react-i18next'

export type ContactTeaserProps = {
  headline: string
  name: string
  opening_hours?: string
  phoneValue?: string
  telefax?: string
  mailValue?: string
  image?: ImageType
  information?: string
  spacing?: string
}

export const ContactTeaser = ({
  headline,
  name,
  opening_hours,
  phoneValue,
  telefax,
  mailValue,
  image,
  information,
  spacing
}: ContactTeaserProps) => {
  const margin = useMarginMaker(spacing)

  const [ref, inView] = useInView({
    rootMargin: '-150px 0px',
    triggerOnce: true,
  })
  const { t } = useTranslation()

  return (
    <section ref={ref} className={`container flex flex-col lg:flex-row`} style={margin}>
      <div className="bg-red p-8 text-white lg:w-4/12 lg:min-w-1/3 lg:px-12">
        <Wobble changeColor={false}>
          <Link href={`tel: ${phoneValue}`}>
            <Icon name="headset" className="mb-4 w-8 text-white" />
          </Link>
        </Wobble>
        <Headline type={HeadlineType.h3} className="mb-0 xl:max-w-75%">
          {headline}
        </Headline>
      </div>
      <div className="flex w-full flex-col bg-gray-light p-8 lg:flex-row lg:p-12">
        <span className=" mr-7 mb-3 max-h-12 lg:mb-0 lg:self-end">
          {image?.url && (
            <Image
              className="rounded-full"
              src={image.url}
              layout="fixed"
              width={48}
              height={48}
              alt={image.alt}
            />
          )}
        </span>

        <div className="flex flex-col justify-end">
          <Trail open={inView}>
            <p className="mb-4">{name}</p>
            {opening_hours ? <p className="mb-4">{opening_hours}</p> : null}
            <span>
              {t('phone') + ':'}
              <Link href={`tel: ${phoneValue}`} className="ml-2">
                {phoneValue}
              </Link>
            </span>
            {telefax ? (
              <span>
                {t('telefax') + ':'}
                <Link href={`tel: ${telefax}`} className="ml-2">
                  {telefax}
                </Link>
              </span>
            ) : null}
            <span>
              {t('mail') + ':'}
              <Link href={`mailto: ${mailValue}`} className="ml-2">
                {mailValue}
              </Link>
            </span>
            {information ? <p className="mt-4 mb-0 text-xs">{information}</p> : null}
          </Trail>
        </div>
      </div>
    </section>
  )
}
