import { Icon } from '../Icon/Icon'

export type Props = {
  clickPrev: () => void
  clickNext: () => void
  disablePrev?: boolean
  disableNext?: boolean
  className?: string
}

export const SlideNav = ({
  clickPrev,
  clickNext,
  disablePrev,
  disableNext,
  className = '',
}: Props) => {
  return (
    <div className={className}>
      <button
        type="button"
        onClick={clickPrev}
        className={`mr-4 inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-red bg-white text-red xl:h-12 xl:w-12 ${
          disablePrev ? 'pointer-default pointer-events-none opacity-50' : ''
        }`}
      >
        <Icon name={'chevron-left'} />
      </button>
      <button
        type="button"
        onClick={clickNext}
        className={`inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-red bg-white text-red xl:h-12 xl:w-12 ${
          disableNext ? 'pointer-default pointer-events-none opacity-50' : ''
        }`}
      >
        <Icon name={'chevron-right'} />
      </button>
    </div>
  )
}
