import Image from '@next/image'
import { useMedia } from 'hooks/useMedia'
import { useMarginMaker } from 'hooks/useMarginMaker'

import { Headline, HeadlineType } from '../../elements/Headline/Headline'
import { ButtonSelectSwitch, LinkType } from '../../elements/ButtonSelectSwitch/ButtonSelectSwitch'
import { ImageType } from 'typings/image'

export type SmallStageProps = {
  image: ImageType
  mobileImage?: ImageType
  headline: string
  subHeadline?: string
  text: string
  linkList: LinkType[]
  spacing?: string
}

export const SmallStage = ({
  image,
  mobileImage = image,
  headline,
  subHeadline,
  text,
  linkList,
  spacing,
}: SmallStageProps) => {
  const isDesktop = useMedia('md')
  const margin = `calc(${useMarginMaker(spacing).marginBottom} + 160px)`

  return (
    <section className="relative">
      <div style={{ marginBottom: margin }} className="h-[133vw] w-full sm:h-75vw lg:h-33vw">
        {image
          ? isDesktop
            ? image.url && <Image src={image.url} fill style={{ objectFit: 'cover' }} alt="" />
            : mobileImage?.url && (
                <Image src={mobileImage.url} fill style={{ objectFit: 'cover' }} alt="" />
              )
          : null}
        <div
          className={`absolute right-4 left-4 -bottom-30 bg-white p-6 shadow-2xl sm:right-12 sm:left-12 sm:p-8 lg:left-auto lg:right-5.5 lg:w-35vw lgx:p-12`}
        >
          {subHeadline && (
            <strong className="mb-2 text-p-small uppercase lg:mb-4">{subHeadline}</strong>
          )}
          <Headline
            type={HeadlineType.h1}
            className="hyphens-auto mb-2 break-words text-h1-mobile-dynamic normal-case text-red sm:mb-4 sm:text-h1-desk-sm-dynamic lg:text-h1-desk-lg-dynamic 2xl:text-h1-large-dynamic"
          >
            {headline}
          </Headline>
          <div
            className={`mx-auto mb-6 max-w-lg sm:max-w-none`}
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
          <ButtonSelectSwitch linkList={linkList} fullWidth />
        </div>
        <div></div>
      </div>
    </section>
  )
}
