import Image from '@next/image'
import { CustomAccordionItem } from './CustomAccordionItem'
import { Headline, HeadlineType } from '~/elements/Headline/Headline'
import { TextLink, TextLinkTheme } from '~/elements/TextLink/TextLink'
import { Accordion as AccordionReach } from '@reach/accordion'
import '@reach/accordion/styles.css'
import { useMedia } from 'hooks/useMedia'
import { useMarginMaker } from 'hooks/useMarginMaker'
import { ImageType } from 'typings/image'
import clsx from 'clsx'

export type Item = {
  itemHeading: string
  richText: string
  image: ImageType
  href?: string
  path?: string
  linkText?: string
}

export type AccordionProps = {
  headline?: string
  text?: string
  itemList: Item[]
  spacing?: string
}

export const Accordion = ({ headline, text, itemList, spacing }: AccordionProps) => {
  const isDesktop = useMedia('md')
  const margin = useMarginMaker(spacing)

  return (
    <section className="flex justify-center" style={margin}>
      <div className="mx-auto w-full max-w-[60rem] p-4 sm:p-0 xl:max-w-text">
        <Headline type={HeadlineType.h2} className="2xl:text-h2-desk">
          {headline}
        </Headline>
        <div className="mx-auto mb-6" dangerouslySetInnerHTML={{ __html: text }}></div>
        <AccordionReach collapsible multiple className="mt-6 w-full border-b border-gray sm:mt-10">
          {itemList.map((item, i) => (
            <CustomAccordionItem key={item.itemHeading + i} itemHeading={item.itemHeading}>
              {item.image && (
                <div className="py-4 px-4 md:px-10 lg:mx-auto lg:max-w-[60%]">
                  <Image
                    src={item.image.url}
                    width={624}
                    height={400}
                    layout="responsive"
                    objectFit="contain"
                    alt={item.image.alt}
                  />
                </div>
              )}

              <div
                className={clsx(
                  'richText accordion-item list-item py-4 sm:py-4',
                  isDesktop ? 'accordion-item-desktop' : 'accordion-item-mobile',
                )}
                dangerouslySetInnerHTML={{ __html: item.richText }}
              ></div>
              {item.href || item.path ? (
                <TextLink
                  className="pb-6"
                  href={item.href ? item.href : item.path}
                  iconSize={12}
                  theme={TextLinkTheme.DARK}
                >
                  {item.linkText ? item.linkText : item.href ? item.href : item.path}
                </TextLink>
              ) : null}
            </CustomAccordionItem>
          ))}
        </AccordionReach>
      </div>
    </section>
  )
}
