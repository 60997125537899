import Image from '@next/image'
import { useInView } from 'react-intersection-observer'
import { Trail } from '../../animations/Trail'
import { Headline, HeadlineType } from '../../elements/Headline/Headline'
import { TextLink } from '../../elements/TextLink/TextLink'
import { useMedia } from 'hooks/useMedia'
import { LinkType } from 'elements/ButtonSelectSwitch/ButtonSelectSwitch'
import { ImageType } from 'typings/image'
import clsx from 'clsx'

export type Props = {
  image: ImageType
  richText: string
  mobileImage?: ImageType
  headline?: string
  imageRight?: boolean
  className?: string
  linkList?: LinkType[]
}

export const ImageTextTeaserRow = ({
  image,
  mobileImage = image,
  richText,
  headline,
  imageRight,
  className = '',
  linkList,
}: Props) => {
  const isDesktop = useMedia('md')
  const [ref, inView] = useInView({
    rootMargin: '-150px 0px',
    triggerOnce: true,
  })

  return (
    <div
      className={clsx(
        'flex flex-col',
        imageRight ? 'md:flex-row-reverse' : 'md:flex-row',
        className && className,
      )}
    >
      <div
        className={`-mx-4 md:mx-0 md:w-1/2 ${
          imageRight ? 'md:ml-4 md:rounded-tl-4xl' : 'md:mr-4 md:rounded-tr-4xl'
        } overflow-hidden `}
      >
        {isDesktop && image?.url ? (
          <Image
            src={image.url}
            width={624}
            height={400}
            layout="responsive"
            className="transform filter transition-all duration-300 ease-in-out hover:scale-110"
            alt={image.alt}
          />
        ) : (
          mobileImage?.url && (
            <Image
              src={mobileImage.url}
              width={312}
              height={200}
              layout="responsive"
              className="-mx-4"
              alt={mobileImage.alt}
            />
          )
        )}
      </div>
      <div
        ref={ref}
        className={clsx(
          'w-full py-4 px-0 md:w-1/2 md:py-0',
          imageRight
            ? 'md:mr-4 lg:mr-10 xl:mr-14 2xl:mr-20'
            : 'md:ml-4 lg:ml-10 xl:ml-16 2xl:ml-20',
        )}
      >
        <Trail open={inView}>
          <Headline type={HeadlineType.h3} className="mb-2 md:mb-4">
            {headline}
          </Headline>

          <div
            className="richText mb-4 md:mb-6"
            dangerouslySetInnerHTML={{ __html: richText }}
          ></div>
          {linkList.map((link) => (
            <TextLink href={link.href} key={link.href}>
              {link.text}
            </TextLink>
          ))}
        </Trail>
      </div>
    </div>
  )
}
