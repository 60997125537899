import { Headline, HeadlineType } from '../../elements/Headline/Headline'
import { ImageTextTeaserRow, Props as RowType } from './ImageTextTeaserRow'
import { useMarginMaker } from 'hooks/useMarginMaker'

export type ImageTextTeaserProps = {
  headline?: string
  teaserList: RowType[]
  spacing?: string
}

export const ImageTextTeaser = ({ headline, teaserList, spacing }: ImageTextTeaserProps) => {
  const margin = useMarginMaker(spacing)
  return (
    <section className="container" style={margin}>
      {headline && (
        <Headline type={HeadlineType.h2} className="text-center text-red md:mb-12">
          {headline}
        </Headline>
      )}
      {teaserList.map((teaser) => (
        <ImageTextTeaserRow {...teaser} className="mb-2 md:mb-20" key={teaser.headline} />
      ))}
    </section>
  )
}
